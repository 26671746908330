import { Colors } from "@blueprintjs/core";
import { useUpAndDowns } from "components/queries/upAndDowns";
import { getColorPaletteAtIndex } from "config";
import { RootState } from "contorller";
import { PortfolioWithFunction } from "contorller/portfolio/types";
import { SettingsState } from "contorller/settings/redux";
import { PICState } from "contorller/simulations/PIC/redux";
import { compact, last, times } from "lodash";
import { DateTime } from "luxon";
import Matrix from "ml-matrix";
import type { Annotations, PlotData, Shape } from "plotly.js";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  AMOUNT,
  DATES,
  FUNCTION_DATA,
  FUNCTION_FILLER,
  INVESTED,
  INVESTED_FIXED_RATE
} from "simulator/types";
import { fromDaysToString } from "utils/portfolios";

type PicCharts = {
  histogram: PlotData[];
  line: PlotData[];
  revision: number;
  quote: PlotData[];
  additionals: PlotData[];
  shapes: Shape[];
  annotations: Annotations[];
};

export function usePicChartsCompare(
  portfolios: PortfolioWithFunction[] | undefined,
  linesData: Array<Matrix> | null,
  // window: { start: DateTime; end: DateTime },
  isRealPic: boolean,
  showInterestsCompare: boolean,
  interestCompareFundId?: string 
): Partial<PicCharts> {
  const { t } = useTranslation();
  const currencyFormatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const percentFormatter = new Intl.NumberFormat("IT-it", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
    style: "percent",
  });
  const [revision, setRevision] = useState(0);
  const { data: upAndDownsData } = useUpAndDowns(interestCompareFundId);
  const { showMeanPerfomance, logarithmic, opacity, colorBlindness } = useSelector<
    RootState,
    SettingsState
  >((s) => s.settings);
  const pic = useSelector<RootState, PICState>((s) => s.pic);
  const applyScale = (values: number[] | null, skipNull=false) => {
    // if (skipNull !== true) {
    //   if (logarithmic && values?.[0] != null) {
    //     const scaleValue = values[0] / Math.log(values[0]);
    //     return values.map((v) => Math.log(v) * scaleValue);
    //   }
    // } else if (compact(values??[])[0] != null){
    //   if (logarithmic) {
    //     const firstValue = 100
    //     const scaleValue = firstValue / Math.log(firstValue);
    //     console.log("VALUES: ", values)
    //     console.log("FIRST VALUE: ",firstValue)
    //     console.log("SCALE VALUE: ",scaleValue)
    //       return values!.map((v) => v != null ? Math.log(v) * scaleValue: null);
    //   }
    // }
      return values;
  };
  const x = useMemo(() => {
    return (
      linesData?.[0]
        ?.getRow(DATES)
        ?.map(
          (d) =>
            `${DateTime.fromSeconds(d).toLocaleString(DateTime.DATE_SHORT)}`
        ) ?? []
    );
  }, [linesData]);

  const { graphOptions } = pic;
  const { fundHoverId, fundIdsHidden, showSingleFunds } = graphOptions;
  const { line } = useMemo<Pick<PicCharts, "line">>(() => {
    const hoverinfo = "x+text+name";
    let line: PlotData[] = [];
    if (portfolios != null && linesData != null && linesData.length > 0) {
      for (let i=0; i< portfolios.length; i++) {
        if (linesData[i] != null) {
          const isPortfolioHover = fundHoverId == null || fundHoverId === portfolios[i]?.compareId;
          const amountY = applyScale(linesData[i].getRow(AMOUNT));
          const ptfIScatter = !fundIdsHidden.includes(portfolios[i]?.compareId ?? "")
          ? (
              {
                type: "scatter",
                name: portfolios[i]?.name ?? "Portafoglio",
                x,
                y: amountY,
                hoverinfo,
                opacity: isPortfolioHover ? 1 : 0.2,
                yaxis: {},
                text: amountY?.map((d) => currencyFormatter.format(d)),
                marker: {
                  color: getColorPaletteAtIndex(i+3),
                },
              } as unknown as PlotData
            ) 
          : null;
          if (ptfIScatter != null) {
            if (portfolios[i].functionType != null && linesData[i].rows > FUNCTION_DATA) {
              const functionData = [...times(portfolios[i].functionDaysPeriod ?? 0).map(()=> null),...(linesData[i].getRow(FUNCTION_DATA).slice(portfolios[i].functionDaysPeriod ?? 0))]
              const fillerData = compact(linesData[i].getRow(FUNCTION_FILLER))
              const ptfName = portfolios[i].name.length > 25 ? `${portfolios[i].name.slice(0,22)}...` : portfolios[i].name
              const duration = fromDaysToString(portfolios[i].functionDaysPeriod ?? 0)
              ptfIScatter.hoverinfo = "x+text"
              ptfIScatter.text = amountY!.map((d,idx) => {
                const isFromFiller = 
                  functionData[idx] == null && fillerData[idx] != null
                const value = isFromFiller ? fillerData[idx] : functionData[idx];
                const amount =
                  d != null && value != null
                    ? `${percentFormatter.format(d / value - 1)}`
                    : "";
                return `<b>${ptfName}</b>: ${currencyFormatter.format(d)}<br><b>${isFromFiller ? t("Stima "):""}${t(`AlertGoalType_${portfolios[i].functionType}`)} (${duration})</b>: ${currencyFormatter.format(value!)}<br><b>Variaz. %</b>: ${amount}`
              })
              line.push(ptfIScatter);
              const name = `${portfolios[i]?.name ?? t("Portafoglio")} - ${t(`AlertGoalType_${portfolios[i].functionType}`)} (${duration})`
              line.push(
                {
                  type: "scatter",
                  name: name,
                  x,
                  y: applyScale(functionData,true),
                  hoverinfo: "none",
                  opacity: isPortfolioHover ? 0.5 : 0.1,
                  line: {
                    color: getColorPaletteAtIndex(i+3),
                  },
                } as unknown as PlotData
              )
              line.push(
                {
                  type: "scatter",
                  name: `${t("Stima")} ${name}`,
                  x,
                  y: applyScale(fillerData),
                  hoverinfo: "none",
                  opacity: isPortfolioHover ? 0.5 : 0.1,
                  yaxis: {},
                  line: {
                    color: getColorPaletteAtIndex(i+3),
                    dash: "dash",
                  },
                } as unknown as PlotData
              );
            } else {
              line.push(ptfIScatter);
            }
          }
        }
      }
      const isInvestedHover = fundHoverId == null || fundHoverId === "invested";
      const y = applyScale(linesData?.[0]?.getRow(INVESTED));
      const invested =
        !fundIdsHidden.includes("invested") && isRealPic
          ? ({
            type: "scatter",
            name: "Investito",
            x,
            y,
            hoverinfo,
            text: y?.map((d) => currencyFormatter.format(d)),
            // hovertemplate,
            opacity: isInvestedHover ? 1 : 0.2,
            marker: {
              opacity,
              color: getColorPaletteAtIndex(1),
            },
          } as unknown as PlotData)
          : null;
      const isPurchasingHover = fundHoverId == null || fundHoverId === "invested-fixed-rate";
      const fixedRateY = applyScale(linesData?.[0]?.getRow(INVESTED_FIXED_RATE));
      const purchasingPower =
        !fundIdsHidden.includes("invested-fixed-rate") && isRealPic
          ? ({
            type: "scatter",
            name: "Investito Tasso Fisso",
            x,
            y: fixedRateY,
            hoverinfo,
            opacity: isPurchasingHover ? 1 : 0.2,
            text: fixedRateY?.map((d) => currencyFormatter.format(d)),
            marker: {
              color: getColorPaletteAtIndex(2),
            },
          } as unknown as PlotData)
          : null;
      line = compact([...line, invested, purchasingPower]);
    }
    setRevision((p) => p + 1);
    return { line };
  }, [
    pic,
    fundHoverId,
    opacity,
    showSingleFunds,
    fundIdsHidden,
    linesData,
    logarithmic,
  ]);

  type Ret = {
    shapes: Shape[];
    annotations: Annotations[];
  };
  
  const { shapes, annotations } = useMemo<Ret>(() => {
    if (!showInterestsCompare || upAndDownsData?.values == null) {
      return { shapes: [], annotations: [] };
    }
    const total = upAndDownsData.values.length;
    const xStartDate = parsePlotXDate(x[0])
    const xEndDate = parsePlotXDate(x[x.length-1])
    return upAndDownsData.values.reduce<Ret>(
      (prev, val, index) => {
        
        const startIndex = Math.max(Math.ceil(
          DateTime.fromSeconds(val.Unix).diff(xStartDate, "days").days
        ), 0);
        const nextDate =
          upAndDownsData.values[index + 1]?.Unix ?? xEndDate.toSeconds();
        const endIndex = Math.ceil(
          DateTime.fromSeconds(nextDate).diff(xStartDate, "days").days
        );
        const visible = ((nextDate >= xStartDate.toSeconds()) && (val.Unix <= xEndDate.toSeconds()))
        let color:string;
        if (val.Close < 0) {
          color = colorBlindness === true ? Colors.BLUE3 : Colors.GREEN3;
        } else if (val.Close === 0) {
          color = Colors.LIGHT_GRAY3;
        } else {
          color = colorBlindness === true ? Colors.GOLD3 : Colors.RED3;
        }
        const x0 = x[(startIndex < 0 ? 0 : startIndex)]
        const x1 = (index === total - 1 ? last(x) : x[endIndex] == null ? last(x): x[endIndex]) as string
        const annotationsVisible = ((visible) && (x0 !== x[0]) && (x1 !== last(x)))
        const shape: Partial<Shape> = {
          type: "rect",
          xref: "x",
          yref: "paper",
          x0: x0,
          y0: 0,
          x1: x1,
          y1: 1,
          fillcolor: color,
          opacity: 0.2,
          line: {
            width: 1,
            color: "rgba(0,0,0,0.2)",
          },
          visible:visible,
          
        };
       
        let annotation: Partial<Annotations> | null = null;
        if (val.Close !== 0) {
          annotation = {
            xref: "x",
            xanchor: "center",
            yref: "paper",
            x: x[startIndex + Math.round((x.indexOf(x1) - startIndex) / 2.0)],
            y: 0.975,
            text: `${val.Close}%`,
            font: {
              color: "black",
            },
            opacity: 0.7,
            visible:visible ,
            showarrow: false,
            textangle: "-90"
          };
        }

        return {
          shapes: [...prev.shapes, shape],
          annotations: compact([...prev.annotations, annotation]),
        } as Ret;
      },
      {
        shapes: [],
        annotations: [],
      }
    );
  }, [upAndDownsData, line, showInterestsCompare, x, colorBlindness]);

  return {
    line,
    shapes,
    annotations,
    revision,
  };
}

function parsePlotXDate(date: string):DateTime{
  if (date != null){
    const date_array = date.split("/")
    const day = `0${date_array[0]}`
    const month = `0${date_array[1]}`
    const newDateString = `${day.slice(-2)}/${month.slice(-2)}/${date_array[2]}`
    return DateTime.fromFormat(newDateString, "dd/MM/yyyy")
  }
  return DateTime.now()
}